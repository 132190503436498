import Vue from "vue"
import {mapGetters} from "vuex"
import axios from "axios"
import VueCookies from "vue-cookies"

import lazySizes from 'lazysizes'
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper'
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/autoplay"
import InstantSearch from 'vue-instantsearch'
import algoliasearch from 'algoliasearch/lite'
import VueI18n from 'vue-i18n'
import translations from "@/js/translations.js"
import SlideUpDown from 'vue-slide-up-down'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Notifications from 'vue-notification'
import store from "@/js/store.js"
import {helpers} from "@/js/mixins/helpers.js"
import {cart} from "@/js/mixins/cart.js"
import {extend} from 'vee-validate'
import {required} from 'vee-validate/dist/rules'
import {checkVAT, countries} from 'jsvat-next'
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock"
import searchInsights from "search-insights"
import Choices from "choices.js"

extend('required', required)

const main = async () => {
    
    // App main
    Vue.use(VueI18n)
    Vue.use(VueCookies)
    Vue.use(InstantSearch)
    Vue.use(Notifications)
    
    Vue.component('slide-up-down', SlideUpDown)
    Vue.component('v-select', vSelect)
    
    // Get Twig translations
    let mergedTranslations = {};
    let twigTranslations = {};
    if (window.t) twigTranslations = JSON.parse(window.t);
    Object.entries(translations).forEach(([key, val]) => {
        const translationsFromTwig = twigTranslations[key] ?? {};
        mergedTranslations[key] = { ...val, ...translationsFromTwig }
    });
    
    const i18n = new VueI18n({
        messages: mergedTranslations
    })
    
    window.axios = axios
    window.i18n = i18n
    
    const vm = new Vue({
        el: "#site",
        delimiters: ['${', '}'],
        store,
        i18n,
        
        mixins: [
            helpers,
            cart
        ],
        
        components: {
            'algolia-webshop': () => import(/* webpackChunkName: "algolia-webshop" */ '../vue/AlgoliaWebshop.vue'),
            'recommended-products': () => import(/* webpackChunkName: "recommended-products" */ '../vue/RecommendedProducts.vue'),
            'product-card': () => import(/* webpackChunkName: "product-card" */ '../vue/components/ProductCard.vue'),
            'customer-select': () => import(/* webpackChunkName: "customer-select" */ '../vue/CustomerSelect.vue'),
            'contextual-search': () => import(/* webpackChunkName: "contextual-search" */ '../vue/ContextualSearch.vue'),
            'customer-search': () => import(/* webpackChunkName: "customer-search" */ '../vue/CustomerSearch.vue'),
            'saved-orders': () => import(/* webpackChunkName: "saved-orders" */ '../vue/SavedOrders.vue'),
            'product-buy': () => import(/* webpackChunkName: "product-buy" */ '../vue/ProductBuy.vue'),
            'delivery-picker': () => import(/* webpackChunkName: "delivery-picker" */ '../vue/DeliveryPicker.vue'),
            'pickup-date-picker': () => import(/* webpackChunkName: "pickup-date-picker" */ '../vue/PickupDatePicker.vue'),
            'products-autocomplete': () => import(/* webpackChunkName: "products-autocomplete" */ '../vue/ProductsAutocomplete.vue'),
            'animated-number': () => import(/* webpackChunkName: "animated-number" */ '../vue/AnimatedNumber.vue'),
            'date-range': () => import(/* webpackChunkName: "date-range" */ '../vue/DateRange.vue'),
            'sales-map': () => import(/* webpackChunkName: "sales-map" */ '../vue/SalesMap.vue'),
            'cart-mini': () => import(/* webpackChunkName: "cart-mini" */ '../vue/CartMini.vue'),
            'cart-mini-mobile': () => import(/* webpackChunkName: "cart-mini-mobile" */ '../vue/CartMiniMobile.vue'),
            'cart': () => import(/* webpackChunkName: "cart" */ '../vue/Cart.vue'),
            'stock-modal': () => import(/* webpackChunkName: "cart-mini-mobile" */ '../vue/StockModal.vue'),
            'calendar': () => import(/* webpackChunkName: "cart-mini-mobile" */ '../vue/Calendar.vue'),
            'customer-search-subscription': () => import(/* webpackChunkName: "customer-search-subscription" */ '../vue/CustomerSearchSubscription.vue'),
        },
        
        data: {
            // Cookie consent
            showStatusBar: true,
            navOpen: false,
            
            // Algolia
            searchClient: algoliasearch(
                'VOYNA2HOMW',
                'a40c9abd596160f234d82e9c7bf3597b'
            ),
            
            // Sales app
            currentOpenAccordions: [],
            selectedCartSort: 'created',
            
            // Timeline block
            timelineId: 1,
            
            // Promotions tabs
            activePromoTabId: 0,
            activePromoSubTabId: "sub-1",
            swiperInstances: [],
            
            defaultSwiperConfig: {
                modules: [Navigation, Pagination, Autoplay],
                slidesPerView: 1,
                speed: 1500,
                watchOverflow: true,
                spaceBetween: 40,
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            },
            
            resetPassword: false,
            payByEmailModal: false,
            showPageModal: true,
            
            // checkout
            deliveryAxCode: null,
            inventSiteId: null,
            pickupDates: null,
            logoutTime: 10,
            cutOff: 0
        },
        
        mounted() {
            // Init lazysizes
            lazySizes.init()
            
            // Init formie
            if (window.Formie) window.Formie.initForms()
            
            // Set Algolia user token
            let algoliaUserToken = "RansonGuest"
            let isDevMode = false
            const devMode = document.head.querySelector('meta[name="dev-mode"]')
            if (devMode) isDevMode = window.isDevMode = devMode.content === "1"
            
            const userUid = document.head.querySelector('meta[name="user-uid"]')
            
            // Filter out events while in dev-mode -> no user token = no events
            if (!isDevMode && userUid) algoliaUserToken = userUid.content
            this.$store.commit("SET_ALGOLIA_USER_TOKEN", algoliaUserToken)
            
            // Set language
            const rootUrlMeta = document.head.querySelector('meta[name="site-url"]')
            window.rootUrl = rootUrlMeta.content

            const language = document.head.querySelector('meta[name="language"]')
            if (language) {
                window.language = language.content
                this.$i18n.locale = language.content
            } else {
                window.language = 'nl'
                this.$i18n.locale = 'nl'
                console.error("language not found. Switching to default NL.")
            }
            
            // Set CSRF token
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]')
            if (csrfToken) {
                window.csrfToken = csrfToken.content
                window.axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken.content
            } else {
                console.error("CSRF token not found.")
            }
            
            // Is it the UK site
            const isUkSite = document.head.querySelector('meta[name="is-uk-site"]')
            if (isUkSite) {
                window.isUkSite = isUkSite.content === "1"
            } else {
                console.error("isUkSite not found.")
            }
            
            // Load cart
            this.$store.dispatch('loadCart').then(() => {
                this.calculateCartShippingDifference()
            })
            
            this.initHomePage()
            this.initSalesAppHomePage()
            this.initProductCarousel()
            this.initEtalageCarousel({
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                speed: 1500,
                watchOverflow: true,
                loop: false,
                spaceBetween: 40,
                noSwiping: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            })
            this.initLogoCarousel()
            this.initBlockImages()
            this.initBlockTextAndImage()
            this.initDoubleScrolling()
            this.initRegisterFormValidation()
            this.initKioskCarousel({
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                speed: 750,
                watchOverflow: true,
                loop: false,
                spaceBetween: 40,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                on: {
                    slideChange: function(swiper) {
                        let index = swiper.activeIndex + 1
                        let currentSlideEl = document.querySelector('.kiosk-slide-count')
                        currentSlideEl.textContent = index
                    }
                }
            })
            
            this.initKioskHeroCarousel({
                spaceBetween: 0
            })

            this.injectProcessingStatusId()
            this.injectStatusIdAdaptData()
            this.setOnboardingFormReadOnly()
            this.initShippingMethod()
            this.setSalesHoeselt()
            this.initInspirationsForm()
            this.initOpenCartsFilter()
            this.initLogoutTimer()
            this.initArticleForm()
            this.initArticlesFilter()
            //this.injectStatusIdArticleForm()
        },
        
        methods: {
            heroScrollTo() {
                window.scrollTo(0, window.outerHeight);
            },
            
            initLogoutTimer() {
                // Is timer present?
                const timerEl = document.querySelector('.logout-timer')

                if(timerEl) {
                    const logoutUrl = timerEl.dataset.logout
                    let timeLeft = 10;
                    let downloadTimer = setInterval(() => {
                        if(timeLeft <= 0){
                            clearInterval(downloadTimer);
                            window.location = logoutUrl
                        }
                        this.logoutTime = timeLeft;
                        timeLeft -= 1;
                    }, 1000);
                }

            },

            toggleLanguageSwitcher(e) {
                e.preventDefault()
                const languageSwitcher = document.querySelector('.kiosk-header .submenu')
                if(languageSwitcher) {
                    if(languageSwitcher.style.display == 'block') {
                        languageSwitcher.style.display = 'none'
                    } else {
                        languageSwitcher.style.display = 'block'
                    }
                }
            },

            addAllProductsToCart(e) {
                const form = e.target
                const data = new FormData(form)

                window.axios({
                    url: '/',
                    method: 'POST',
                    data: data
                }).then(result => {
                    if (result.status === 200) {
                        // Show notification
                        this.$notify({
                            text: window.i18n.t("success.addedToCart"),
                            type: "success",
                        });

                        // Save cart
                        if (result?.data?.cart) {
                            this.$store.dispatch('saveCart', result.data.cart);
                        }

                        // Send Algolia event
                        if (this.algoliaQueryId) {
                            searchInsights('convertedObjectIDsAfterSearch', {
                                index: `products_${window.language}`,
                                eventName: 'Product added to cart.',
                                queryID: this.algoliaQueryId,
                                objectIDs: [currentProduct.id.toString()],
                            });
                        }

                        // Reset qty for the added product
                        //this.updateQty(null, currentProduct, 1)
                    } else {
                        this.$notify({
                            title: window.i18n.t("errors.generalTitle"),
                            text: window.i18n.t("errors.notAddedToCart"),
                            type: "error",
                        });
                        console.error("Cart error.", result);
                    }
                }).catch((error) => {
                    this.$notify({
                        title: window.i18n.t("errors.generalTitle"),
                        text: window.i18n.t("errors.cartNotUpdated"),
                        type: "error",
                    });
                    console.error("Cart error.", error);
                })
            },

            togglePayByEmailModal({ type, modalData, sku, name }) {
                // Show the modal
                this.payByEmailModal = true
                
                // Get the form
                const form = document.querySelector("[data-id='formie-form-pay-by-email']")
                if (form) {
                    // Inject data into the hidden fields, we save the product ID instead of the file sheet url, so it won't be visible in the code
                    const fieldArticleNumber = form.querySelector("[data-fui-id='pay-by-email-article-number']")
                    const fieldArticleName = form.querySelector("[data-fui-id='pay-by-email-article-name']")
                    const fieldDataType = form.querySelector("[data-fui-id='pay-by-email-data-type']")
                    const fieldModalData = form.querySelector("[data-fui-id='pay-by-email-modal-data']")
                    
                    if (fieldArticleNumber) fieldArticleNumber.value = sku
                    if (fieldArticleName) fieldArticleName.value = name
                    if (fieldDataType) fieldDataType.value = type
                    if (fieldModalData) fieldModalData.value = modalData
                }
                
                // Notification data is handled in the Ranson module
            },
            
            showSlide(index, swiper) {
                this.swiperInstances[swiper].slideTo(index, 750)
            },
            
            onSelectDate(day) {
                this.$refs.deliveryDate.value = day.toISOString()
            },
            
            onSelectShippingMethod(e) {
                this.$refs.btnDetailsTop.disabled = false
                this.$refs.btnDetailsBottom.disabled = false

                this.deliveryAxCode = e.target.value
                this.inventSiteId = this.$refs.inventSiteId.value = e.target.options[e.target.selectedIndex].getAttribute('data-invent-site-id')
                this.pickupDates = JSON.parse(e.target.options[e.target.selectedIndex].getAttribute('data-pickup-dates'))
                if (e.target.options[e.target.selectedIndex].hasAttribute('data-cutoff')) {
                    this.cutOff = e.target.options[e.target.selectedIndex].getAttribute('data-cutoff')
                }
                if(this.deliveryAxCode == '00' && this.inventSiteId !== null) {
                    this.$refs.btnDetailsTop.disabled = true
                    this.$refs.btnDetailsBottom.disabled = true
                }
            },
            
            initShippingMethod() {
                if(this.$refs.shippingMethod !== undefined) {
                    this.deliveryAxCode = this.$refs.shippingMethod.value
                    this.pickupDates = JSON.parse(this.$refs.shippingMethod.options[this.$refs.shippingMethod.selectedIndex].getAttribute('data-pickup-dates'))
                    if (this.$refs.shippingMethod.options[this.$refs.shippingMethod.selectedIndex].hasAttribute('data-cutoff')) {
                        this.cutOff = this.$refs.shippingMethod.options[this.$refs.shippingMethod.selectedIndex].getAttribute('data-cutoff')
                    }
                }
                if(this.$refs.inventSiteId !== undefined) {
                    this.inventSiteId = this.$refs.inventSiteId.value
                }
            },
            
            goToSelectUrl(e) {
                window.location.href = e.target.value
            },
            
            setPromoTabId(e, isSub) {
                const id = e.target.value
                if (isSub) {
                    this.activePromoSubTabId = id
                    document.getElementById(id).scrollIntoView()
                    //console.log(document.getElementById(id))
                } else {
                    this.activePromoTabId = parseInt(id, 10)
                }
            },

            setTimelineId(e) {
                const id = e.target.value;

                if (id) {
                    this.timelineId = parseInt(id, 10);
                }
            },
            
            closeStatusBar(slug) {
                VueCookies.set(`status-${ slug }`, 'hidden', '7d')
                this.showStatusBar = false
            },
    
            closePageModal(id) {
                VueCookies.set(`modal-${ id }`, 'hidden', '7d')
                this.showPageModal = false
            },
            
            toggleNav() {
                this.navOpen = !this.navOpen
                this.navOpen ? disableBodyScroll(this.$refs.navigation) : enableBodyScroll(this.$refs.navigation);
            },
            
            sortCart(attribute) {
                // First get all children of the sorting list
                let cartList = document.querySelector('.cart-list-items')
                
                Array.from(cartList.querySelectorAll('.cart-item')).sort(function (a, b) {
                    /* Created needs to be sort in reverse */
                    if (attribute === 'created') {
                        return b.getAttribute('data-' + attribute) - a.getAttribute('data-' + attribute);
                    }

                    /* Sort alphabetical */
                    return a.getAttribute('data-' + attribute) - b.getAttribute('data-' + attribute);
                }).forEach((el) => {
                    cartList.appendChild(el)
                })
                
                this.selectedCartSort = attribute
            },
            
            initDoubleScrolling() {
                // Get all top scrollers
                const scrollEls = document.querySelectorAll('.table-top-scroller')
                for (let scroller of scrollEls) {
                    // Get the content div inside the scroller
                    const content = scroller.querySelector('.scroller-content')
                    // Get the table that belongs to the scroller -> top-scroller always needs to be above the scrolling element!
                    const elToScroll = scroller.nextElementSibling.querySelector('.double-scroll')
                    // Check if it is a table we're scrolling
                    if (elToScroll) {
                        const wrapper = elToScroll.parentElement
                        content.style.width = elToScroll.offsetWidth + 'px'
                        
                        wrapper.addEventListener('scroll', () => {
                            scroller.scrollLeft = wrapper.scrollLeft
                        })
                        
                        scroller.addEventListener('scroll', () => {
                            wrapper.scrollLeft = scroller.scrollLeft
                        })
                    }
                }
            },
            
            initHomePage(config) {
                const swiperEl = document.getElementById('hero-carousel')
                if (swiperEl) {
                    const imageCount = swiperEl.dataset.count
                    const useConfig = config || this.defaultSwiperConfig
                    
                    new Swiper(swiperEl, {
                        ...useConfig,
                        spaceBetween: 0,
                        loop: imageCount > 1,
                    })
                }
            },
            
            initSalesAppHomePage(config) {
                const swiperEl = document.getElementById('sales-app-home-carousel')
                if (swiperEl) {
                    const imageCount = swiperEl.dataset.count
                    const useConfig = config || this.defaultSwiperConfig
                    
                    new Swiper(swiperEl, {
                        loop: imageCount > 1,
                        ...useConfig
                    })
                }
            },
            
            initProductCarousel(config) {
                const swiperEl = document.getElementById('product-carousel')
                if (swiperEl) {
                    const imageCount = swiperEl.dataset.count
                    const useConfig = config || this.defaultSwiperConfig
                    
                    new Swiper(swiperEl, {
                        loop: imageCount > 1,
                        ...useConfig
                    })
                }
            },
            
            initEtalageCarousel(config) {
                const swiperEl = document.getElementById('etalage-carousel')
                if (swiperEl) {
                    const imageCount = swiperEl.dataset.count
                    const useConfig = config || this.defaultSwiperConfig
                    
                    new Swiper(swiperEl, {
                        loop: imageCount > 1,
                        ...useConfig
                    })
                }
            },

            initKioskCarousel(config) {
                const swiperEls = document.querySelectorAll('.kiosk-swiper .swiper')
                if (swiperEls.length) {
                    swiperEls.forEach((swiperEl, index) => {
                        const imageCount = swiperEl.dataset.count
                        const useConfig = config || this.defaultSwiperConfig

                        this.swiperInstances[index] = new Swiper(swiperEl, {
                            loop: imageCount > 1,
                            ...useConfig
                        })
                    })
                }
            },
    
            initKioskHeroCarousel(config) {
                const swiperEls = document.querySelectorAll('.js-kiosk-carousel .swiper')
                if (swiperEls.length) {
                    swiperEls.forEach((swiperEl, index) => {
                        const imageCount = swiperEl.dataset.count
                        const useConfig = config || this.defaultSwiperConfig
                
                        this.swiperInstances[index] = new Swiper(swiperEl, {
                            loop: imageCount > 1,
                            ...useConfig
                        })
                    })
                }
            },
            
            initBlockImages(config) {
                const blockEl = document.querySelectorAll('.block-images')
                for (let block of blockEl) {
                    const swiperEl = (block.getElementsByClassName('swiper').length) ? block.getElementsByClassName('swiper')[0] : false
                    if (swiperEl) {
                        const imageCount = swiperEl.dataset.count
                        const useConfig = config || this.defaultSwiperConfig
                        
                        new Swiper(swiperEl, {
                            ...useConfig,
                            loop: imageCount > 1
                        })
                    }
                }
            },

            initBlockTextAndImage(config) {
                const blockEl = document.querySelectorAll('.block-text-and-image')
                for (let block of blockEl) {
                    const swiperEl = (block.getElementsByClassName('swiper').length) ? block.getElementsByClassName('swiper')[0] : false
                    if (swiperEl) {
                        const imageCount = swiperEl.dataset.count
                        const useConfig = config || this.defaultSwiperConfig

                        new Swiper(swiperEl, {
                            ...useConfig,
                            loop: imageCount > 1,
                            spaceBetween: 0,
                        })
                    }
                }
            },
            
            initLogoCarousel() {
                const blockEl = document.querySelectorAll('.block-logo-slider')
                for (let block of blockEl) {
                    const swiperEl = (block.getElementsByClassName('swiper').length) ? block.getElementsByClassName('swiper')[0] : false
                    
                    if (swiperEl) {
                        const imageCount = swiperEl.dataset.count
                        
                        new Swiper(swiperEl, {
                            loop: imageCount > 1,
                            modules: [Autoplay],
                            preloadImages: false,
                            lazy: true,
                            spaceBetween: 40,
                            slidesPerView: 1,
                            centeredSlides: true,
                            speed: 1000,
                            autoplay: {
                                delay: 2000,
                                disableOnInteraction: false
                            },
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 4
                                },
                                1024: {
                                    slidesPerView: 5,
                                    centeredSlides: false
                                }
                            }
                        })
                    }
                }
            },
            
            initRegisterFormValidation() {
                const formEl = document.querySelector('.register-form')
                if (formEl) {
                    formEl.addEventListener('onFormieValidate', (e) => {
                        // Check for VAT validation
                        const vatField = document.querySelector('input[name="fields[vat]"]')

                        if (vatField.value) {
                            const check = checkVAT(vatField.value, countries)
                            if (!check.isValid) {
                                e.preventDefault()
                                const parent = vatField.closest('.fui-field-container')

                                const errorMessage = document.createElement('div')
                                errorMessage.classList.add('fui-error-message')
                                errorMessage.id = "fui-error-fields"
                                errorMessage.textContent = window.i18n.t("errors.validVatNumber")

                                parent.append(errorMessage)
                            }
                        }
                    })
                }
            },

            /**
             * Inject status id after processing
             * the statusId is defined in twig and can be one of 2 choices
             * - inProcessingArtisan (id = 12)
             * - inProcessingIndustry (id = 13)
             * The id is determined in the template onboarding/new
             * and is dependant of the choice given on the field
             * customerType (Ik ben)
             */
            injectProcessingStatusId() {
                const formEl = document.querySelector('.onboarding-form')
                /**
                 * If status is inProcessing we'll have to add a dropdown which let the user select
                 * to which Spoc the onboarding has to go
                 */
                if(formEl) {

                    if(formEl.classList.contains('inProcessing')) {

                        if(formEl.classList.contains('ranu')) {
                            let input = document.createElement("input")
                            const statusId = (window.onboardingStatusId !== undefined) ? window.onboardingStatusId : 5

                            input.setAttribute("type", "hidden")
                            input.setAttribute("name", "statusId")
                            input.setAttribute("value", statusId)
                            formEl.appendChild(input)

                        } else {
                            const optionValues = [
                                {
                                    label: 'Stuur door naar Artisanaal',
                                    value: 12
                                },
                                {
                                    label: 'Stuur door naar Industrie',
                                    value: 13
                                },
                            ]

                            let wrapper = document.createElement('div')
                            wrapper.classList.add('select-styling','mb-4')

                            let select = document.createElement('select')
                            select.name = 'statusId'
                            select.id = 'statusId'
                            wrapper.appendChild(select)

                            optionValues.forEach((optionValue) => {
                                let option = document.createElement('option')
                                //console.log(optionValue)
                                option.value = optionValue.value
                                option.text = optionValue.label
                                select.appendChild(option)
                            })

                            // Add dropdown as first child element of the form
                            formEl.prepend(wrapper)
                        }

                    }

                    if(formEl.classList.contains('inProcessingShared') || formEl.classList.contains('inProcessingArtisan') || formEl.classList.contains('inProcessingIndustry')) {

                        let input = document.createElement("input")
                        const statusId = (window.onboardingStatusId !== undefined) ? window.onboardingStatusId : 12

                        input.setAttribute("type", "hidden")
                        input.setAttribute("name", "statusId")
                        input.setAttribute("value", statusId)
                        formEl.appendChild(input)
                    }

                    /**
                     * If form is new client form sent directly to Materdata
                     */

                    if(formEl.classList.contains('sales-spoc-form')) {
                        let input = document.createElement("input")
                        const statusId = 5

                        input.setAttribute("type", "hidden")
                        input.setAttribute("name", "statusId")
                        input.setAttribute("value", statusId)
                        formEl.appendChild(input)
                    }
                }
            },

            injectStatusIdAdaptData() {
                const formEl = document.querySelector('.adapt-data')
                if(formEl) {
                    let input = document.createElement("input")
                    input.setAttribute("type", "hidden")
                    input.setAttribute("name", "statusId")
                    input.setAttribute("value", '11')
                    formEl.appendChild(input)
                }
            },

            setOnboardingFormReadOnly() {
                const formEl = document.querySelector('.onboarding-form')
                if(formEl && (formEl.classList.contains('shared-status'))) {
                    const inputs = formEl.querySelectorAll('input, select')
                    inputs.forEach((el) => {
                        //console.log(el)
                        el.disabled = true;
                    })
                }
            },

            setSalesHoeselt() {

                const formEl = document.querySelector('.onboarding-form')
                if(formEl) {
                    const spocDropdown = document.querySelector('select[name="fields[spocEmail][]"]')
                    const hiddenOnboardingSite = document.querySelector('input[name="fields[onboardingSite]"]')
                    const hoeselt = JSON.parse(salesHoeselt)

                    spocDropdown.addEventListener('change', function(e) {
                        // Check if value is present in array
                        const value = e.target.value
                        if(hoeselt.includes(parseInt(value))) {
                            hiddenOnboardingSite.value = 'hoeselt'
                        } else {
                            hiddenOnboardingSite.value = 'stasegem'
                        }
                    })
                }

            },

            initInspirationsForm() {

                const formEl = document.querySelector('.inspirations-form')

                if(formEl) {
                    const addButton = document.querySelector('.fui-repeater-add-btn')
                    const peopleField = document.querySelector('[data-fui-id="inspiration-subscription-aantal-personen"]')

                    peopleField.addEventListener('change', function(e) {
                        addButton.dataset.maxRows = e.target.value
                        window.Formie.forms[0].fieldConfigs.FormieRepeater[0].$field.repeater.maxRows = (e.target.value - 1)
                    })

                    peopleField.addEventListener('blur', function(e) {
                        addButton.dataset.maxRows = e.target.value
                        window.Formie.forms[0].fieldConfigs.FormieRepeater[0].$field.repeater.maxRows = (e.target.value - 1)
                    })

                }

            },

            initOpenCartsFilter() {
                const formEl = document.querySelector('.open-carts-filter-form')
                if(formEl) {
                    const select = formEl.querySelector('select')
                    select.addEventListener('change', function() {
                        formEl.submit()
                    })
                }
            },

            initArticleForm() {

                // Check for edit and change action to normal submit
                const formEl = document.querySelector('.article-form')

                if(formEl) {
                    if(formEl.classList.contains('new-article')) {
                        // Find action hidden input
                        const actionInput = formEl.querySelector('input[name="action"]')
                        if(actionInput) {
                            actionInput.value = "formie/submissions/submit"
                        }
                        // Remove submissionId
                        const submissionIdInput = formEl.querySelector('input[name="submissionId"]')
                        if(submissionIdInput) {
                            submissionIdInput.remove()
                        }
                    }
                }

                // Implement choices
                const elements = document.querySelectorAll('.choices-container')
                let choices = [];

                if(formEl && (formEl.classList.contains('read-only'))) {

                    const inputs = formEl.querySelectorAll('input, select, textarea')
                    inputs.forEach((el) => {
                        console.log(el.type)
                        if(el.type != 'hidden') {
                            el.disabled = true
                        }
                    })

                    if(elements) {
                        elements.forEach(el => {
                            const select = el.querySelector('select')
                            choices.push(new Choices(select).disable())
                        })
                    }

                } else {

                    if(elements) {
                        elements.forEach(el => {
                            const select = el.querySelector('select')
                            choices.push(new Choices(select))
                        })
                    }

                }

            },

            validateCheckoutDetails(event) {

                event.preventDefault();

                const form = event.target
                const fields = form.querySelectorAll('input.required, select.required')
                let errors = false

                fields.forEach(el => {
                    if(el.value == '') {
                        // Set errors to true
                        errors = true;
                        const message = el.dataset.errorMessage

                        const errorMessageDiv = document.createElement('div')
                        errorMessageDiv.classList.add('fui-error-message')
                        errorMessageDiv.innerText = message

                        // Check type
                        if(el.tagName == 'SELECT') {
                            const stylingDiv = el.closest('div.select-styling')
                            stylingDiv.insertAdjacentElement('afterend', errorMessageDiv)
                        } else {
                            el.insertAdjacentElement('afterend', errorMessageDiv)
                        }
                    }
                })

                if(!errors) {
                    form.submit()
                }

            },

            validateCheckoutOverview(event) {
                event.preventDefault();

                const form = event.target

                // Get radio groups by name
                const minimumDropFields = document.getElementsByName('fields[speedDelivery]');
                const obFields = document.getElementsByName('fields[acceptedOb]');

                let errors = false

                // Submit if we do not have those choices
                if(minimumDropFields.length == 0 && obFields.length == 0) {
                    form.submit();
                }

                if(minimumDropFields.length) {
                    let speedDeliveryValue = false
                    let obAcceptedValue = false

                    for(let i= 0; i < minimumDropFields.length; i++) {
                        if(minimumDropFields[i].checked) {
                            speedDeliveryValue = true
                        }
                    }

                    // If we have an error show message below the last field
                    if(!speedDeliveryValue) {
                        errors = true;
                        const el = minimumDropFields[1];
                        const message = el.dataset.errorMessage;

                        // Check if we have an error message
                        const speedErrorDiv = document.querySelector('.speed-delivery-error-message');

                        if(!speedErrorDiv) {
                            const errorMessageDiv = document.createElement('div');
                            errorMessageDiv.classList.add('fui-error-message', 'speed-delivery-error-message');
                            errorMessageDiv.innerText = message;

                            el.parentElement.insertAdjacentElement('afterend', errorMessageDiv);
                        }
                    }
                }

                if(obFields.length) {
                    let obAcceptedValue = false;
                    for(let i= 0; i < obFields.length; i++) {
                        if(obFields[i].checked) {
                            obAcceptedValue = true
                        }
                    }
                    if(!obAcceptedValue) {
                        errors = true;
                        const el = obFields[0];
                        const message = el.dataset.errorMessage;

                        // Check if we have an error message
                        const obErrorDiv = document.querySelector('.ob-error-message');

                        if(!obErrorDiv) {
                            const errorMessageDiv = document.createElement('div')
                            errorMessageDiv.classList.add('fui-error-message', 'ob-error-message')
                            errorMessageDiv.innerText = message

                            el.parentElement.insertAdjacentElement('afterend', errorMessageDiv)
                        }
                    }
                }

                if(!errors) {
                    form.submit()
                }

            },

            initArticlesFilter() {
                const formEl = document.querySelector('.article-filter-form')
                if(formEl) {
                    const selects = formEl.querySelectorAll('select')
                    selects.forEach(select => {
                        select.addEventListener('change', function() {
                            formEl.submit()
                        })
                    })
                }
            },


            injectStatusIdArticleForm() {
                const formEl = document.querySelector('.article-form')

                if (formEl) {
                    const statusId = formEl.dataset.statusId

                    let input = document.createElement("input")
                    input.setAttribute("type", "hidden")
                    input.setAttribute("name", "newStatusId")
                    input.setAttribute("value", statusId)
                    formEl.appendChild(input)
                }
            },

        },
        
        computed: {
            ...mapGetters([
                'getLineItems',
                'getCartTotal'
            ]),
        }
    })
    
    return vm
    
}

// Execute async function
main().then(vm => {
    document.body.classList.add('loaded')
    document.addEventListener("DOMContentLoaded", function (event) {
        checkTimeout();
        
        // Load Sales app agenda
        if (document.body.classList.contains("sales-app-home")) {
            fetchAgenda();
            //fetchOrderLineItems(); // Code with Ajax loading of line items.
        }
    })
})

function checkTimeout() {
    fetch('/actions/users/session-info?dontExtendSession=1', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data.isGuest === false && data.timeout !== -1) {
                if (data.timeout <= 10) {
                    location.href = '/logout';
                } else {
                    setTimeout(function () {
                        checkTimeout()
                    }, (10 * 1000))
                }
            }
        })
}

async function fetchAgenda() {
    const anchorEl = document.getElementById("ajax-agenda");
    if (anchorEl) {
        const response = await fetch(`${window.rootUrl}sales-app/ajax/agenda.twig`, {
            method: 'GET'
        });
        
        if (response.status === 200 && response.ok) {
            const templateHtml = await response.text();
            if (templateHtml) {
                anchorEl.innerHTML = templateHtml;
            }
        } else {
            anchorEl.innerHTML = "Kon agenda niet laden.";
        }
    } else {
        console.error("Geen HTML element gevonden om agenda data te tonen.");
    }
}

function fetchOrderLineItems() {
    const rows = document.querySelectorAll("[id^='order-']");
    
    rows.forEach(async row => {
        const id = row.id.substring(6);
        const response = await fetch(`${window.rootUrl}sales-app/ajax/line-item?orderId=${ id }`, {
            method: 'GET'
        });
        
        if (response.status === 200 && response.ok) {
            const templateHtml = await response.text();
            if (templateHtml) {
                row.innerHTML = templateHtml;
            }
        } else {
            row.innerHTML = `
                <tr>
                    <td colspan="5">Kon bestellingen niet laden.</td>
                </tr>
            `;
        }
    })
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept()
}
